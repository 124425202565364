import React, { useState } from 'react';
import { Table, Button, Form, Spinner } from 'react-bootstrap';
import './Empleados.css';

function TablaEmpleados({ empleados = [], onVerCajas, onEditarRol, handleChangeState, loading }) {  // Agregar "loading" como prop
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const empleadosPerPage = 9;

  const filteredEmpleados = empleados.filter((empleado) =>
    `${empleado.name} ${empleado.lastName}`.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const indexOfLastEmpleado = currentPage * empleadosPerPage;
  const indexOfFirstEmpleado = indexOfLastEmpleado - empleadosPerPage;
  const currentEmpleados = filteredEmpleados.slice(indexOfFirstEmpleado, indexOfLastEmpleado);

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Resetear a la primera página en la búsqueda
  };

  return (
    <div>
      <Form.Group controlId="search" className="mb-3">
        <Form.Control
          type="text"
          placeholder="Buscar empleado por nombre o apellido"
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </Form.Group>

      {loading ? (  // Mostrar spinner mientras se carga
        <div className="d-flex justify-content-center mt-4">
          <Spinner animation="border" role="status">
            <span className="sr-only">Cargando empleados...</span>
          </Spinner>
        </div>
      ) : (
        <>
          <Table striped bordered hover className="mt-4">
            <thead>
              <tr>
                <th>Nombre</th>
                <th>Apellido</th>
                <th>Email</th>
                <th>CUIT</th>
                <th>Cajas</th>
                <th>Estado</th> 
                <th>Acciones</th> 
              </tr>
            </thead>
            <tbody>
              {currentEmpleados.length > 0 ? (
                currentEmpleados.map((empleado) => (
                  <tr key={empleado._id}>
                    <td>{empleado.name}</td>
                    <td>{empleado.lastName}</td>
                    <td>{empleado.email}</td>
                    <td>{empleado.cuit}</td>
                    <td>
                      <Button variant="link" onClick={() => onVerCajas(empleado)}>
                        Ver Cajas
                      </Button>
                    </td>
                    <td>
                      <Form.Check
                        type="switch"
                        id={`switch-${empleado._id}`}
                        label={empleado.isActive ? 'Activo' : 'Inactivo'}
                        checked={empleado.isActive}
                        onChange={() => handleChangeState(empleado._id, empleado.isActive)} 
                      />
                    </td>
                    <td>
                      <Button variant="link" onClick={() => onEditarRol(empleado)}>
                        Editar
                      </Button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="7" className="text-center">No hay empleados para mostrar</td>
                </tr>
              )}
            </tbody>
          </Table>

          <div className="d-flex justify-content-between">
            <Button
              className={`pagination-button ${currentPage === 1 ? 'disabled' : ''}`}
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
            >
              Anterior
            </Button>
            <Button
              className={`pagination-button ${indexOfLastEmpleado >= filteredEmpleados.length ? 'disabled' : ''}`}
              onClick={handleNextPage}
              disabled={indexOfLastEmpleado >= filteredEmpleados.length}
            >
              Siguiente
            </Button>
          </div>
        </>
      )}
    </div>
  );
}

export default TablaEmpleados;
