import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import axios from 'axios';

function EditarRolModal({ show, handleClose, empleado, onRoleChange, rolesDisponibles }) {
  const [selectedRole, setSelectedRole] = useState(empleado ? empleado.role : '');
  const [sucursales, setSucursales] = useState([]);  // Estado para almacenar las sucursales
  const [selectedBranch, setSelectedBranch] = useState(empleado?.sucursal?.id || '');  // Sucursal seleccionada (actual)
  const [currentSucursal, setCurrentSucursal] = useState(empleado?.sucursal?.name || 'Ninguna'); // Sucursal actual
  const [isLoadingSucursales, setIsLoadingSucursales] = useState(false);  // Controla la carga de sucursales

  // Obtener sucursales solo si el rol seleccionado es "gsuc"
  useEffect(() => {
    if (selectedRole === 'gsuc') {
      const fetchSucursales = async () => {
        setIsLoadingSucursales(true);
        try {
          const token = localStorage.getItem('token');
          const response = await axios.get(`${process.env.REACT_APP_URL}/public/v2/List/Branches`, {
            headers: {
              'SERVICIOS-URBANOS-TOKEN': token,
            },
          });
          if (response.data.ok) {
            setSucursales(response.data.msg);  // Asumimos que msg contiene la lista de sucursales
          }
        } catch (error) {
          console.error('Error al obtener sucursales:', error);
        } finally {
          setIsLoadingSucursales(false);
        }
      };

      fetchSucursales();
    }
  }, [selectedRole]);

  const handleSave = async () => {
    await onRoleChange(empleado._id, selectedRole);
    
    // Si el rol es "gsuc", hacer la solicitud para cambiar la sucursal
    if (selectedRole === 'gsuc' && selectedBranch) {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.post(
          `${process.env.REACT_APP_URL}/public/v2/ChangeBranch/GSuc`,
          { id: empleado._id, branch: selectedBranch },
          {
            headers: {
              'SERVICIOS-URBANOS-TOKEN': token,
            }
          }
        );
        if (response.data.ok) {
          console.log('Sucursal cambiada correctamente');
          // Actualizar la sucursal actual en el estado del modal
          const sucursalSeleccionada = sucursales.find(sucursal => sucursal.id === selectedBranch);
          setCurrentSucursal(sucursalSeleccionada?.name || 'Ninguna');  // Actualiza el nombre de la sucursal en tiempo real
        }
      } catch (error) {
        console.error('Error al cambiar sucursal:', error);
      }
    }

    handleClose(); // Cerrar el modal después de guardar
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Editar Rol para {empleado?.name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group controlId="formRol">
          <Form.Label>Seleccionar Rol</Form.Label>
          <Form.Control
            as="select"
            value={selectedRole}
            onChange={(e) => setSelectedRole(e.target.value)}
          >
            {rolesDisponibles.map((role) => (
              <option key={role} value={role}>
                {role}
              </option>
            ))}
          </Form.Control>
        </Form.Group>

        {/* Mostrar el select de sucursales solo si el rol seleccionado es gsuc */}
        {selectedRole === 'gsuc' && (
          <Form.Group controlId="formBranch" className="mt-3">
            <Form.Label>Seleccionar Sucursal (Actual: {currentSucursal})</Form.Label>
            {isLoadingSucursales ? (
              <p>Cargando sucursales...</p>
            ) : (
              <Form.Control
                as="select"
                value={selectedBranch}
                onChange={(e) => setSelectedBranch(e.target.value)}
              >
                <option value="">Seleccionar sucursal...</option>
                {sucursales.map((sucursal) => (
                  <option key={sucursal.id} value={sucursal.id}>
                    {sucursal.name}
                  </option>
                ))}
              </Form.Control>
            )}
          </Form.Group>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancelar
        </Button>
        <Button variant="primary" onClick={handleSave} disabled={selectedRole === 'gsuc' && !selectedBranch}>
          Guardar cambios
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default EditarRolModal;
