import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Spinner, Button, Card } from 'react-bootstrap';
import { useFetchEmpleados } from '../../../Hooks/Susa/useFetchEmpleados';
import TablaEmpleados from './TablaEmpleados';
import TablaCajas from './TablaCajas';
import DetalleCaja from './DetalleCaja';
import TablaHistorial from './TablaHistorial';
import EditarRolModal from './ModalEditar'; 

function ListaDeEmpleados() {
  const API_URL = process.env.REACT_APP_URL;
  const [currentView, setCurrentView] = useState('empleados');
  const [empleados, setEmpleados] = useState([]);  // Estado para empleados
  const [cajas, setCajas] = useState([]);  // Estado para manejar cajas
  const [detalleCaja, setDetalleCaja] = useState(null);  // Estado para manejar el detalle de la caja
  const [historial, setHistorial] = useState([]);  // Estado para el historial
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);  // Estado para mostrar el modal
  const [empleadoSeleccionado, setEmpleadoSeleccionado] = useState(null);  // Estado para el empleado seleccionado para editar rol
  const [selectedIdBox, setSelectedIdBox] = useState('');  // ID de la caja seleccionada

  // Hook para obtener empleados
  const { empleados: fetchedEmpleados, error: empleadosError, empleadosLoading } = useFetchEmpleados(API_URL);

  // Actualizar el estado de empleados cuando se obtienen desde el hook
  useEffect(() => {
    if (fetchedEmpleados && fetchedEmpleados.length > 0) {
      console.log('Empleados obtenidos:', fetchedEmpleados);
      setEmpleados(fetchedEmpleados); // Solo actualizamos si obtenemos datos
    } else if (empleadosError) {
      console.error('Error al obtener empleados:', empleadosError);
    }
  }, [fetchedEmpleados, empleadosError]);

  // Función para manejar la vista de cajas al seleccionar un empleado
  const handleVerCajas = async (empleado) => {
    setLoading(true);
    const token = localStorage.getItem('token');

    try {
      const response = await axios.post(
        `${API_URL}/public/v2/List/Employees/One`,
        { idEmployees: empleado._id },
        {
          headers: {
            'SERVICIOS-URBANOS-TOKEN': token,
          },
        }
      );

      if (response.data.ok) {
        setCajas(response.data.msg); // Guardar las cajas obtenidas
        setEmpleadoSeleccionado(empleado);  // Guardar empleado seleccionado
        setCurrentView('cajas');  // Cambiar vista a cajas
      } else {
        console.error('Error al obtener las cajas del empleado:', response.data.msg);
      }
    } catch (err) {
      console.error('Error en la solicitud de cajas:', err);
    } finally {
      setLoading(false);
    }
  };

  // Función para ver el detalle de una caja
  const handleVerDetalleCaja = async (idBox) => {
    setSelectedIdBox(idBox);
    setLoading(true);
    const token = localStorage.getItem('token');

    try {
      const response = await axios.post(
        `${API_URL}/public/v2/List/Employees/One/Box`,
        { idBox },
        {
          headers: {
            'SERVICIOS-URBANOS-TOKEN': token,
          },
        }
      );

      if (response.data.ok) {
        setDetalleCaja(response.data.msg[0]);  // Guardar el detalle de la caja
        setCurrentView('detalleCaja');  // Cambiar vista a detalle de la caja
      } else {
        console.error('Error al obtener el detalle de la caja:', response.data.msg);
      }
    } catch (err) {
      console.error('Error en la solicitud de detalle de caja:', err);
    } finally {
      setLoading(false);
    }
  };

  // Función para ver el historial de movimientos de una caja
  const handleVerHistorico = async (idBox) => {
    setLoading(true);
    const token = localStorage.getItem('token');

    try {
      const response = await axios.post(
        `${API_URL}/public/v2/List/History`,
        { idBox },
        {
          headers: {
            'SERVICIOS-URBANOS-TOKEN': token,
          },
        }
      );

      if (response.data.ok) {
        setHistorial(response.data.msg);  // Guardar el historial
        setCurrentView('historial');  // Cambiar vista a historial
      } else {
        console.error('Error al obtener el historial:', response.data.msg);
      }
    } catch (err) {
      console.error('Error en la solicitud de historial:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleEditarRol = (empleado) => {
    setEmpleadoSeleccionado(empleado);  
    setShowModal(true);  
  };

  // Función para cambiar el rol de un empleado
  const handleRoleChange = async (idEmpleado, nuevoRol) => {
    setLoading(true);
    const token = localStorage.getItem('token');

    try {
      const response = await axios.post(
        `${API_URL}/public/v2/ChangeRole`,
        { id: idEmpleado, role: nuevoRol },
        {
          headers: {
            'SERVICIOS-URBANOS-TOKEN': token,
          },
        }
      );

      if (response.data.ok) {
     
        setEmpleados((prevEmpleados) =>
          prevEmpleados.map((empleado) =>
            empleado._id === idEmpleado ? { ...empleado, role: nuevoRol } : empleado
          )
        );
        setShowModal(false);  
      }
    } catch (err) {
      console.error('Error al cambiar el rol del empleado:', err);
    } finally {
      setLoading(false);
    }
  };

  // Función para cambiar el estado de activo/inactivo
  const handleChangeState = async (empleadoId, currentState) => {
    setLoading(true);
    const token = localStorage.getItem('token');

    try {
      const response = await axios.post(
        `${API_URL}/public/v2/ChangeState/Employees`,
        { id: empleadoId, state: !currentState },
        {
          headers: {
            'SERVICIOS-URBANOS-TOKEN': token,
          },
        }
      );

      if (response.data.ok) {
        setEmpleados((prevEmpleados) =>
          prevEmpleados.map((empleado) =>
            empleado._id === empleadoId ? { ...empleado, isActive: !currentState } : empleado
          )
        );
      }
    } catch (err) {
      console.error('Error al cambiar el estado del empleado:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleBack = () => {
    if (currentView === 'historial') {
      setCurrentView('detalleCaja');
    } else if (currentView === 'detalleCaja') {
      setCurrentView('cajas');
    } else if (currentView === 'cajas') {
      setCurrentView('empleados');
    }
  };

  return (
    <Container className='mt-5'>
      {loading && <Spinner animation="border" />}

      {currentView !== 'empleados' && (
        <Button variant="secondary" onClick={handleBack} className="mb-3">
          ← Volver
        </Button>
      )}

      {currentView === 'empleados' && (
        <Card className="mb-4">
          <Card.Body>
            <Card.Title>Lista de Empleados</Card.Title>
            <TablaEmpleados 
              empleados={empleados} 
              onVerCajas={handleVerCajas} 
              onEditarRol={handleEditarRol} 
              handleChangeState={handleChangeState} 
              loading={empleadosLoading}
            />
          </Card.Body>
        </Card>
      )}

      {currentView === 'cajas' && (
        <Card className="mb-4">
          <Card.Body>
            <Card.Title>Lista de Cajas</Card.Title>
            <TablaCajas cajas={cajas} onVerDetalleCaja={handleVerDetalleCaja} />
          </Card.Body>
        </Card>
      )}

      {currentView === 'detalleCaja' && (
        <Card className="mb-4">
          <Card.Body>
            <Card.Title>Detalle de la Caja</Card.Title>
            <DetalleCaja detalle={detalleCaja} idBox={selectedIdBox} onVerHistorico={handleVerHistorico} />
          </Card.Body>
        </Card>
      )}

      {currentView === 'historial' && historial.length > 0 && (
        <Card className="mb-4">
          <Card.Body>
            <Card.Title>Historial de Movimientos</Card.Title>
            <TablaHistorial movimientos={historial} />
          </Card.Body>
        </Card>
      )}

      {/* Modal para editar roles */}
      {empleadoSeleccionado && (
        <EditarRolModal
          show={showModal}
          handleClose={() => setShowModal(false)}
          empleado={empleadoSeleccionado}
          onRoleChange={handleRoleChange}
          rolesDisponibles={['comercio', 'gsuc', 'Emp', 'audit']}  
        />
      )}
    </Container>
  );
}

export default ListaDeEmpleados;
