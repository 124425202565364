import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import './ConfirmModal.css'; 

const OpenModal = ({ show, handleClose, handleConfirm, sucursalName }) => {
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton className="confirm-modal-header">
        <Modal.Title className="confirm-modal-title">Abrir caja</Modal.Title>
      </Modal.Header>
      <Modal.Body className="confirm-modal-body">
        {sucursalName ? (
          <p>¿Seguro que deseas abrir la caja de la sucursal <strong>{sucursalName}</strong>?</p>
        ) : (
          <p>¿Seguro que deseas abrir la caja?</p>
        )}
      </Modal.Body>
      <Modal.Footer className="confirm-modal-footer">
        <Button variant="secondary" onClick={handleClose} className="confirm-modal-cancel">
          Cancelar
        </Button>
        <Button variant="primary" onClick={handleConfirm} className="confirm-modal-button">
          Confirmar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default OpenModal;
