import React, { useState, useEffect } from 'react';
import { Table, Button, Spinner } from 'react-bootstrap';
import './Empleados.css';

function TablaCajas({ cajas, onVerDetalleCaja, loading }) {
  const [currentPage, setCurrentPage] = useState(1);
  const cajasPerPage = 5;
  const [selectedIdBox, setSelectedIdBox] = useState(''); 

  const indexOfLastCaja = currentPage * cajasPerPage;
  const indexOfFirstCaja = indexOfLastCaja - cajasPerPage;
  const currentCajas = cajas.slice(indexOfFirstCaja, indexOfLastCaja);

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const handleVerDetalleClick = (idBox, idBranches) => {
    console.log('idBox guardado en TablaCajas:', idBox); 
    setSelectedIdBox(idBox); 
    onVerDetalleCaja(idBox, idBranches); 
  };
   
  

  useEffect(() => {
    setCurrentPage(1);
  }, [cajas]);

  
  if (loading) {
    return (
      <div className="mt-4 d-flex justify-content-center">
        <Spinner animation="border" role="status">
          <span className="sr-only">Cargando...</span>
        </Spinner>
      </div>
    );
  }

  return (
    <div className="mt-4">
      {currentCajas.length > 0 ? (
        <>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>ID de Caja</th> 
                <th>Fecha de Apertura</th>
                <th>Balance</th>
                <th>Fecha de Cierre</th>
                <th>Ver Detalle de Caja</th>
              </tr>
            </thead>
            <tbody>
              {currentCajas.map((caja) => (
                
                <tr key={caja._id}>
                  <td>{caja.idBox}</td> 
                 <td>{caja.dateOpen ? caja.dateOpen.replace('Z', '').split('T').join(' ') : '-'}</td>
                  <td>{caja.balance !== null && caja.balance !== undefined ? caja.balance : '-'}</td>


                  <td>{caja.dateClose ? caja.dateClose.replace('Z', '').split('T').join(' ') : '-'}</td>
                  <td>
                    <Button
                      variant="link"
                      onClick={() => handleVerDetalleClick(caja.idBox, caja.idBranches)}
                    >
                      Ver Detalle
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <div className="d-flex justify-content-between">
            <Button
              className={`pagination-button ${currentPage === 1 ? 'disabled' : ''}`}
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
            >
              Anterior
            </Button>
            <Button
              className={`pagination-button ${indexOfLastCaja >= cajas.length ? 'disabled' : ''}`}
              onClick={handleNextPage}
              disabled={indexOfLastCaja >= cajas.length}
            >
              Siguiente
            </Button>
          </div>
        </>
      ) : (
        <p>No se encontraron cajas.</p>
      )}
    </div>
  );
}

export default TablaCajas;
