import React, { useState } from 'react';
import { Container, Row, Col, Alert, Card } from 'react-bootstrap';
import UploadForm from './UploadForm';
import SearchForm from './SearchForm';
import SearchResults from './SearchResults';
import axios from 'axios';
import './Comprobantes.css';

function Comprobantes() {
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [searchResults, setSearchResults] = useState({ data: [], urls: [] });
  const [searchError, setSearchError] = useState('');
  const [searchDate, setSearchDate] = useState('');
  const [noResultsMessage, setNoResultsMessage] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [showNoResultsAlert, setShowNoResultsAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(false); 

  const formatDateString = (dateString) => {
    const [year, month, day] = dateString.split('-');
    return `${day}-${month}-${year}`;
  };

  const handleSearch = async () => {
    setIsLoading(true); // Iniciar el indicador de carga
    setNoResultsMessage('');
    setSearchError('');
    setCurrentPage(1);
    setShowNoResultsAlert(false);

    try {
      const data = {
        cuit: localStorage.getItem('cuit'),
        fecha: searchDate ? searchDate : ''
      };

      let response = await axios.post(`${process.env.REACT_APP_URL}/public/v1/Shops/File/View`, data, {
        headers: {
          'type': 'application/json',
          'SERVICIOS-URBANOS-TOKEN': localStorage.getItem('token'),
          'page': 1
        }
      });

      if (response.data.urls && response.data.urls.length === 0 && searchDate) {
        setNoResultsMessage(`No se encontraron comprobantes para la fecha ${formatDateString(searchDate)}. Mostrando todos los comprobantes...`);
        setShowNoResultsAlert(true);

        // Mostrar el mensaje antes de la segunda búsqueda
        setSearchResults({ data: [], urls: [] });
        setIsLoading(false);

        response = await axios.post(`${process.env.REACT_APP_URL}/public/v1/Shops/File/View`, {
          cuit: localStorage.getItem('cuit'),
          fecha: ''
        }, {
          headers: {
            'Content-Type': 'application/json',
            'SERVICIOS-URBANOS-TOKEN': localStorage.getItem('token'),
            'page': 1
          }
        });
      }

      if (response.data.urls && response.data.urls.length === 0) {
        setNoResultsMessage('No se encontraron comprobantes');
        setShowNoResultsAlert(true);
      }

      setSearchResults({ data: response.data.urls.map(item => item.data), urls: response.data.urls });
    } catch (error) {
      setSearchError('Error al buscar comprobantes');
      //console.error('Error fetching comprobantes:', error);
    } finally {
      setIsLoading(false); // Detener el indicador de carga
    }
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const paginatedResults = searchResults.data.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  return (
    <Container className="comprobantes-container">
      <Card className="comprobantes-card">
        <Row className="justify-content-md-center">
          <Col md="12">
            <h2 className="comprobantes-header">Subir Comprobantes</h2>
            {message && <Alert variant="success">{message}</Alert>}
            {error && <Alert variant="danger">{error}</Alert>}
            <UploadForm setMessage={setMessage} setError={setError} />
            <h2 className="comprobantes-header mt-4">Buscar Comprobantes</h2>
            <SearchForm 
              handleSearch={handleSearch} 
              setSearchDate={setSearchDate} 
              searchError={searchError} 
            />
            <div className="mt-3">
              {noResultsMessage && <Alert variant="danger">{noResultsMessage}</Alert>} 
              {isLoading ? (
                <Alert variant="info">Cargando comprobantes...</Alert>
              ) : (
                <SearchResults 
                  searchResults={{ data: paginatedResults, urls: searchResults.urls }} 
                />
              )}
            </div>
            <div className="pagination">
              <button 
                onClick={() => handlePageChange(currentPage - 1)} 
                disabled={currentPage === 1}
              >
                Anterior
              </button>
              <button 
                onClick={() => handlePageChange(currentPage + 1)} 
                disabled={currentPage * itemsPerPage >= searchResults.data.length}
              >
                Siguiente
              </button>
            </div>
          </Col>
        </Row>
      </Card>
    </Container>
  );
}

export default Comprobantes;
