import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Card } from 'react-bootstrap';
import './Sucursales.css';

const SucursalesComercio = () => {
  const [sucursales, setSucursales] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchSucursales = async () => {
      const token = localStorage.getItem('token');
      const API_URL = process.env.REACT_APP_URL;

      // Verifica que el token exista
      if (!token) {
        setError('No se encontró el token de autenticación.');
        return;
      }

      try {
        const response = await axios.get(
          `${API_URL}/public/v2/List/Branches`,
          {
            headers: {
              'SERVICIOS-URBANOS-TOKEN': token,
            },
          }
        );
        if (response.data.ok) {
          setSucursales(response.data.msg);
        } else {
          setError('No se pudo obtener la lista de sucursales.');
        }
      } catch (err) {
        console.error('Error:', err.response || err.message); 
        setError('Error al obtener la lista de sucursales. Verifique la consola para más detalles.');
      }
    };

    fetchSucursales();
  }, []);

  return (
    <div className="lista-sucursales-container">
      <Card className="lista-sucursales-card">
        <Card.Body>
          <h2 className="lista-sucursales-header">Lista de Sucursales</h2>
          {error && <p className="text-danger">{error}</p>}
          <Table striped bordered hover className="lista-sucursales-table">
            <thead>
              <tr>
                <th>#</th>
                <th>Nombre</th>
                <th>Estado</th>
              </tr>
            </thead>
            <tbody>
              {sucursales.map((sucursal, index) => (
                <tr key={sucursal.id}>
                  <td>{index + 1}</td>
                  <td>{sucursal.name}</td>
                  <td>{sucursal.state ? 'Activo' : 'Inactivo'}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </div>
  );
};

export default SucursalesComercio;
