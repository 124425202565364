import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Alert, Spinner, Card, Table, Button } from 'react-bootstrap';
import { FaSync } from 'react-icons/fa'; 
import ModalDetalleTransferencia from './ModalDetalleTransferencia';
import ModalTransferencia from './ModalTransferencia';
import BuscarTransferenciasPorFecha from './BuscadorTransferencia';
import './ListaDeTransferencias.css';

function ListaTransferenciasAuditor() {
  const [transferencias, setTransferencias] = useState([]);
  const [transferenciasFiltradas, setTransferenciasFiltradas] = useState([]); // Guardar transferencias filtradas
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [showModalDetalle, setShowModalDetalle] = useState(false);
  const [showModalTransferencia, setShowModalTransferencia] = useState(false);
  const [transferenciaSeleccionada, setTransferenciaSeleccionada] = useState(null);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const API_URL = process.env.REACT_APP_URL;
  const SECRET = process.env.REACT_APP_SECRET;

  useEffect(() => {
    fetchTransferencias(page);
  }, [page]);

  const fetchTransferencias = async (page) => {
    setLoading(true);
    const token = localStorage.getItem('token');
    try {
      const response = await axios.post(
        `${API_URL}/public/Mov/Transfer/Audit`,
        {
          page: page,
        },
        {
          headers: {
            'SERVICIOS-URBANOS-TOKEN': token,
            'Secret': SECRET,
          },
        }
      );

      if (response.data.ok) {
        setTransferencias(response.data.msg || []);
        setTransferenciasFiltradas(response.data.msg || []); // Inicialmente, todas las transferencias están visibles
        setTotalPages(response.data.totalPage);
      } else {
        setError(response.data.msg || 'Error al mostrar las transferencias.');
      }
    } catch (err) {
      const apiErrorMessage = err.response?.data?.msg || err.response?.data?.message || 'Error desconocido.';
      setError(apiErrorMessage);
    } finally {
      setLoading(false);
    }
  };

  const handleVerDetalles = (transferencia) => {
    setTransferenciaSeleccionada(transferencia);
    setShowModalDetalle(true);
  };

  const refreshTransferencias = () => {
    fetchTransferencias(page); // Refresca las transferencias actuales
  };

  const conceptosMap = {
    ALQ: 'Alquiler',
    CUO: 'Cuota',
    EXP: 'Expensas',
    FAC: 'Factura',
    PRE: 'Préstamo',
    SEG: 'Seguro',
    HON: 'Honorarios',
    HAB: 'Haberes',
    VAR: 'Varios',
  };

  const handleTransferenciaCreada = () => {
    fetchTransferencias(); // Refresca la lista de transferencias
    setShowModalTransferencia(false); // Cierra el modal después de hacer la transferencia
  };

  const handleNextPage = () => {
    if (page < totalPages) {
      setPage(page + 1);
    }
  };

  const handlePreviousPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const handleTransferenciasFiltradas = (nuevasTransferencias) => {
    setTransferenciasFiltradas(nuevasTransferencias);
  };

  return (
    <Container className='mt-5'>
      {error && <Alert variant="danger" onClose={() => setError('')} dismissible>{error}</Alert>}
      {loading && <Spinner animation="border" />}

      <Card className="mb-4">
        <Card.Body>
          <div className="d-flex justify-content-between align-items-center">
            <Card.Title>Transferencias</Card.Title>
            <div className="d-flex justify-content-end align-items-center">
              <Button
                variant="link"
                className="ms-2"
                onClick={refreshTransferencias}
                title="Actualizar lista"
                style={{ color: '#118a80' }} 
              >
                <FaSync />
              </Button>
              <BuscarTransferenciasPorFecha
                transferencias={transferencias}
                onTransferenciasFiltradas={handleTransferenciasFiltradas}
              />
              {/*<Button
                className="btn btn-primary d-flex align-items-center ms-2 custom-button" // Aplica custom-button para el botón de Transferir
                onClick={() => setShowModalTransferencia(true)}
              >
                <FaMoneyCheckAlt className="me-2" />
                Transferir
              </Button>*/}
            </div>
          </div>

          <Table striped bordered hover className="mt-4">
            <thead>
              <tr>
                <th>ID</th> 
                <th>Fecha</th>
                <th>Tipo</th>
                <th>Balance</th>
                <th>Concepto</th>
                <th>Ver Detalle</th>
              </tr>
            </thead>
            <tbody>
              {transferenciasFiltradas.length > 0 ? (
                transferenciasFiltradas.map((transferencia, index) => {
                  const timestamp = transferencia.timestamp;
                  const formattedDate = `${timestamp.substring(8, 10)}-${timestamp.substring(5, 7)}-${timestamp.substring(0, 4)} ${timestamp.substring(11, 19)}`;

                  return (
                    <tr key={index}>
                      <td>{transferencia.origin_id}</td>
                      <td>{formattedDate}</td>
                      <td>{transferencia.movimiento.types}</td>
                      <td>{transferencia.movimiento.balance}</td>
                      <td>{conceptosMap[transferencia.movimiento.concept] || transferencia.movimiento.concept}</td>
                      <td>
                        <Button
                          variant="link"
                          onClick={() => handleVerDetalles(transferencia)}
                          style={{ color: '#118a80', textDecoration: 'underline' }}
                        >
                          Ver Detalle
                        </Button>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="5" className="text-center">No hay transferencias disponibles</td>
                </tr>
              )}
            </tbody>
          </Table>

          <div className="d-flex justify-content-between">
            <Button
              className={`pagination-button ${page === 1 ? 'disabled' : ''}`}
              onClick={handlePreviousPage}
              disabled={page === 1}
            >
              Anterior
            </Button>
            <Button
              className={`pagination-button ${page >= totalPages ? 'disabled' : ''}`}
              onClick={handleNextPage}
              disabled={page >= totalPages}
            >
              Siguiente
            </Button>
          </div>
        </Card.Body>
      </Card>

      {showModalDetalle && (
        <ModalDetalleTransferencia
          show={showModalDetalle}
          transferencia={transferenciaSeleccionada}
          handleClose={() => setShowModalDetalle(false)}
        />
      )}

      {showModalTransferencia && (
        <ModalTransferencia
          show={showModalTransferencia}
          handleClose={() => setShowModalTransferencia(false)}
          onTransferenciaCreada={handleTransferenciaCreada}
          API_URL={API_URL}
          SECRET={SECRET}
        />
      )}
    </Container>
  );
}

export default ListaTransferenciasAuditor;
