import { useState } from "react";
import axios from "axios";

const { REACT_APP_URL: API_URL } = process.env;

export const useCashOut = () => {
  const [userData, setUserData] = useState(undefined);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const token = localStorage.getItem("token");
  const headers = {
    "SERVICIOS-URBANOS-TOKEN": token,
  };

  const finished = () => {
    setLoading(false);
    setTimeout(() => {
      setError("");
      setSuccess(false);
    }, 3000);
  };

  const getInfoCashOut = async (cashOutData) => {
    setLoading(true);
    try {
      const { data } = await axios.post(
        `${API_URL}/public/v2/ExistExtractionCash`,
        cashOutData,
        { headers }
      );

      if (data.ok) {
        setUserData(data.data);
      } else {
        setError(data.msg);
      }
    } catch (err) {
      setError(
        err.response?.data?.msg ||
          err.response?.data ||
          "Error al obtener información de la extracción."
      );
      console.error("Error desconocido: ", err);
    } finally {
      finished();
    }
  };

  const postCashOut = async (cashOutData) => {
    setLoading(true);
    try {
      const { data } = await axios.post(
        `${API_URL}/public/v2/ExtractionCash`,
        cashOutData,
        { headers }
      );

      if (!data.ok) {
        setError(data.msg);
        return;
      }
      setSuccess(true);
    } catch (err) {
      setError(
        err.response?.data?.msg ||
          err.response?.data ||
          "Error al intentar extraer el saldo."
      );
      console.error("Error desconocido: ", err);
    } finally {
      finished();
    }
  };

  return { userData, loading, success, error, getInfoCashOut, postCashOut };
};
