import React, { useState } from 'react';
import { Modal, Button, Form, Alert, ListGroup } from 'react-bootstrap';
import axios from 'axios';

function ModalAgregarCuenta({ show, handleClose, onCuentaAdded, API_URL, SECRET }) {
  const [cvu, setCvu] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);
  const [accountData, setAccountData] = useState(null); // Estado para almacenar los datos de la cuenta
  const [isVerified, setIsVerified] = useState(false); // Estado para verificar si el CVU ha sido verificado
  const [isAdded, setIsAdded] = useState(false); // Estado para saber si la cuenta ha sido agregada con éxito

  const handleVerifyCuenta = async () => {
    setLoading(true);
    setError('');
    setSuccess('');
    setAccountData(null);
    const token = localStorage.getItem('token');

    try {
      const response = await axios.post(
        `${API_URL}/public/Exists`,
        { cvuDest: cvu },
        {
          headers: {
            'SERVICIOS-URBANOS-TOKEN': token,
            'SECRET': SECRET,
          },
        }
      );

      if (response.data.ok) {
        setSuccess('Cuenta verificada con éxito.');
        setAccountData(response.data.msg.data); // Guardar los datos de la cuenta
        setIsVerified(true); // Marcar que la cuenta ha sido verificada
        setIsAdded(false); // Asegurarse de que no se muestre el mensaje de "Cuenta agregada" antes de tiempo
      } else {
        setError(response.data.msg || 'Error al verificar la cuenta.');
      }
    } catch (err) {
      const apiErrorMessage = err.response?.data?.msg || err.response?.data?.message || 'Error desconocido.';
      setError(apiErrorMessage);
     // console.error('Error al verificar cuenta:', err.response || err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleAddCuenta = async () => {
    setLoading(true);
    setError('');
    setSuccess('');
    const token = localStorage.getItem('token');

    try {
      const body = {
        id: accountData.owners[0].id, // ID del propietario obtenido de la verificación
        label: accountData.label,
        account_routing: {
          scheme: "CVU",
          address: cvu
        },
        bank_routing: {
          scheme: "NAME",
          address: accountData.bank_routing.address,
          code: accountData.bank_routing.code
        }
      };

      const response = await axios.post(
        `${API_URL}/public/Accounts`,
        body,
        {
          headers: {
            'SERVICIOS-URBANOS-TOKEN': token,
          },
        }
      );

      if (response.data.ok) {
        setSuccess(`Cuenta agregada con éxito: CVU ${cvu}, Nombre ${accountData.owners[0].display_name}.`);
        setIsAdded(true); // Marcar que la cuenta ha sido agregada con éxito
        setAccountData(null); // Limpiar datos de la cuenta verificada
        setIsVerified(false); // Volver al estado inicial para permitir otra verificación
      } else {
        setError(response.data.msg || 'Error al agregar la cuenta.');
      }
    } catch (err) {
      const apiErrorMessage = err.response?.data?.msg || err.response?.data?.message || 'Error desconocido.';
      setError(apiErrorMessage);
      console.error('Error al agregar cuenta:', err.response || err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleAddAnotherCuenta = () => {
    // Reiniciar los estados para agregar otra cuenta
    setCvu('');
    setError('');
    setSuccess('');
    setAccountData(null);
    setIsVerified(false);
    setIsAdded(false);
  };

  const handleCloseWithUpdate = () => {
    onCuentaAdded(); // Llamar a onCuentaAdded para actualizar la lista de cuentas
    handleClose(); // Cerrar el modal
  };

  return (
    <Modal show={show} onHide={handleCloseWithUpdate}>
      <Modal.Header closeButton>
        <Modal.Title>
          {!isVerified && !isAdded ? 'Verificar Cuenta' : isVerified && !isAdded ? 'Agregar Cuenta' : 'Agregar Otra Cuenta'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error && <Alert variant="danger">{error}</Alert>}
        {success && <Alert variant="success">{success}</Alert>}
        {/* Mostrar el formulario solo si la cuenta no ha sido verificada o se agrega otra cuenta */}
        {!isVerified && !isAdded && (
          <Form>
            <Form.Group controlId="formCVU">
              <Form.Label>CVU</Form.Label>
              <Form.Control
                type="text"
                placeholder="Ingrese CVU"
                value={cvu}
                onChange={(e) => setCvu(e.target.value)}
              />
            </Form.Group>
          </Form>
        )}

        {/* Mostrar datos de la cuenta si están disponibles y la cuenta ha sido verificada */}
        {accountData && isVerified && !isAdded && (
          <ListGroup className="mt-4">
            <ListGroup.Item><strong>Nombre:</strong> {accountData.owners[0].display_name}</ListGroup.Item>
            <ListGroup.Item><strong>ID Tipo:</strong> {accountData.owners[0].id_type}</ListGroup.Item>
            <ListGroup.Item><strong>CUIT:</strong> {accountData.owners[0].id}</ListGroup.Item>
            <ListGroup.Item><strong>Persona Física:</strong> {accountData.owners[0].is_physical_person ? 'Sí' : 'No'}</ListGroup.Item>
            <ListGroup.Item><strong>Tipo de Cuenta:</strong> {accountData.type}</ListGroup.Item>
            <ListGroup.Item><strong>Moneda:</strong> {accountData.currency}</ListGroup.Item>
            <ListGroup.Item><strong>Estado:</strong> {accountData.is_active ? 'Activa' : 'Inactiva'}</ListGroup.Item>
            <ListGroup.Item><strong>Banco:</strong> {accountData.bank_routing.address}</ListGroup.Item>
          </ListGroup>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" className="btn-cerrar" onClick={handleCloseWithUpdate}>
          Cerrar
        </Button>
        {!isVerified && !isAdded ? (
          <Button variant="primary" className="agregar-button" onClick={handleVerifyCuenta} disabled={loading}>
            {loading ? 'Verificando...' : 'Verificar Cuenta'}
          </Button>
        ) : isVerified && !isAdded ? (
          <Button variant="primary" className="agregar-button" onClick={handleAddCuenta} disabled={loading}>
            {loading ? 'Agregando...' : 'Agregar Cuenta'}
          </Button>
        ) : (
          <Button variant="primary" className="agregar-button" onClick={handleAddAnotherCuenta}>
            Agregar Otra Cuenta
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default ModalAgregarCuenta;
