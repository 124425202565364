import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Container, Alert, Spinner, Card, Table, Form } from 'react-bootstrap';

function ListaSucursales() {
  const [sucursales, setSucursales] = useState([]); 
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [loadingState, setLoadingState] = useState(false);

  const API_URL = process.env.REACT_APP_URL;

  useEffect(() => {
    let isMounted = true;

    const fetchSucursales = async () => {
      setLoading(true);
      const token = localStorage.getItem('token');
      
      try {
        const response = await axios.get(
          `${API_URL}/public/v2/List/Branches`,
          {
            headers: {
              'SERVICIOS-URBANOS-TOKEN': token,
            },
          }
        );

        if (isMounted) {
          if (response.data.ok) {
            const sucursalesData = Array.isArray(response.data.msg) ? response.data.msg : [];
            setSucursales(sucursalesData);
          } else {
            setError(response.data.msg || 'Error desconocido.');
          }
        }
      } catch (err) {
        if (isMounted) {
          const apiErrorMessage = err.response?.data?.msg || err.response?.data?.message || 'Error desconocido.';
          setError(apiErrorMessage);
        //  console.error('Error al obtener sucursales:', err.response || err.message);
        }
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    fetchSucursales();

    return () => {
      isMounted = false;
    };
  }, [API_URL]);

  const handleChangeState = async (sucursalId, currentState) => {
    setLoadingState(true);
    setError('');
    const token = localStorage.getItem('token');

    try {
      const response = await axios.post(
        `${API_URL}/public/v2/ChangeState/Branches`,
        { id: sucursalId, state: !currentState },
        {
          headers: {
            'SERVICIOS-URBANOS-TOKEN': token,
          },
        }
      );

      if (response.data.ok) {
        console.log('Cambio de estado exitoso:', response.data.msg); 
        setSucursales((prevSucursales) =>
          prevSucursales.map((sucursal) =>
            sucursal.id === sucursalId ? { ...sucursal, state: !currentState } : sucursal
          )
        );
      } else {
        setError(`Error: ${response.data.msg}`);
      }
    } catch (err) {
      console.error('Error al cambiar el estado de la sucursal:', err);
      setError('Error al cambiar el estado de la sucursal.');
    } finally {
      setLoadingState(false);
    }
  };

  const filteredSucursales = sucursales ? sucursales.filter((sucursal) =>
    sucursal.name.toLowerCase().includes(searchTerm.toLowerCase())
  ) : []; 

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
    <Container className='mt-5'>
      {error && <Alert variant="danger" onClose={() => setError('')} dismissible>{error}</Alert>}
      {loading && <Spinner animation="border" />}
      
      <Card className="mb-4">
        <Card.Body>
          <Card.Title>Lista de Sucursales</Card.Title>
             


             
          <Form.Group controlId="search" className="mb-3">
            <Form.Control
              type="text"
              placeholder="Buscar sucursal por nombre"
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </Form.Group>

          <Table striped bordered hover className="mt-4">
            <thead>
              <tr>
                <th>#</th>
                <th>Nombre</th>
                <th>Estado</th>
              </tr>
            </thead>
            <tbody>
              {filteredSucursales.length > 0 ? (
                filteredSucursales.map((sucursal, index) => (
                  <tr key={sucursal.id}>
                    <td>{index + 1}</td>
                    <td>{sucursal.name}</td>
                    <td>
                      <Form.Check
                        type="switch"
                        id={`switch-${sucursal.id}`}
                        label={loadingState ? 'Cambiando...' : (sucursal.state ? 'Activo' : 'Inactivo')}
                        checked={sucursal.state}
                        onChange={() => handleChangeState(sucursal.id, sucursal.state)}
                        disabled={loadingState}
                      />
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="3" className="text-center">No se encontraron sucursales</td>
                </tr>
              )}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </Container>
  );
}

export default ListaSucursales;
