import React, { useState } from "react";
import { Nav } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMoneyBillWave,
  faStore,
  faSignOutAlt,
  faCogs,
  faList,
  faWallet,
  faCashRegister,
  faUserPlus,
  faBuilding,
  faUsers,
  faHandHoldingDollar,
  faMoneyBillTransfer,
} from "@fortawesome/free-solid-svg-icons";
import "./Sidebar.css";
import { useNavigate } from "react-router-dom";
import useFetchBranches from "../Hooks/Auditor/useFetchBranches";

function Sidebar() {
  const [isAdminOpen, setIsAdminOpen] = useState(false);
  const [isSaldosOpen, setIsSaldosOpen] = useState(false);
  const [isEmpleadosOpen, setIsEmpleadosOpen] = useState(false);
  const [isSucursalesOpen, setIsSucursalesOpen] = useState(false);

  const role = localStorage.getItem("role") || "Emp"; // Si no hay rol, asigna 'Emp'
  const nameShop = localStorage.getItem("SUSA"); // Asume que el usuario es SUSA si tiene nameShop
  const token = localStorage.getItem("token");

  const navigate = useNavigate();
  const { branches, loading, error } = useFetchBranches(token);

  const handleAdminClick = () => setIsAdminOpen(!isAdminOpen);
  const handleSaldosClick = () => setIsSaldosOpen(!isSaldosOpen);
  const handleEmpleadosClick = () => setIsEmpleadosOpen(!isEmpleadosOpen);
  const handleSucursalesClick = () => setIsSucursalesOpen(!isSucursalesOpen);
  const handleLogout = () => navigate("/funciones-administrativas/logout");

  return (
    <div className="sidebar">
      <Nav className="flex-column">
        {/* Opciones para Comercio o Gerente de Sucursal (GSuc), excluyendo Auditor y Empleado */}
        {(role === "comercio" || nameShop) &&
          role !== "audit" &&
          role !== "Emp" &&
          role !== "gsuc" && (
            <>
              <LinkContainer to="/inicio">
                <Nav.Link>
                  <FontAwesomeIcon icon={faStore} className="fa-icon" /> Inicio
                </Nav.Link>
              </LinkContainer>

              <Nav.Item>
                <Nav.Link onClick={handleEmpleadosClick}>
                  <FontAwesomeIcon icon={faUsers} className="fa-icon" />
                  Empleados
                </Nav.Link>
              </Nav.Item>
              {isEmpleadosOpen && (
                <>
                  <LinkContainer to="/agregar-empleado">
                    <Nav.Link className="sub-option">
                      <FontAwesomeIcon icon={faUserPlus} className="fa-icon" />
                      Agregar empleados
                    </Nav.Link>
                  </LinkContainer>
                  <LinkContainer to="/lista-empleados">
                    <Nav.Link className="sub-option">
                      <FontAwesomeIcon icon={faList} className="fa-icon" />
                      Lista Empleados
                    </Nav.Link>
                  </LinkContainer>
                </>
              )}

              <Nav.Item>
                <Nav.Link onClick={handleSucursalesClick}>
                  <FontAwesomeIcon icon={faBuilding} className="fa-icon" />
                  Sucursales
                </Nav.Link>
              </Nav.Item>
              {isSucursalesOpen && (
                <>
                  <LinkContainer to="/agregar-sucursal">
                    <Nav.Link className="sub-option">
                      <FontAwesomeIcon icon={faBuilding} className="fa-icon" />
                      Agregar sucursales
                    </Nav.Link>
                  </LinkContainer>
                  <LinkContainer to="/lista-de-sucursales">
                    <Nav.Link className="sub-option">
                      <FontAwesomeIcon icon={faList} className="fa-icon" />
                      Lista Sucursales
                    </Nav.Link>
                  </LinkContainer>
                </>
              )}

              <Nav.Item>
                <LinkContainer to="/lista-de-transferencias">
                  <Nav.Link>
                    <FontAwesomeIcon
                      icon={faMoneyBillTransfer}
                      className="fa-icon"
                    />
                    Transferencias
                  </Nav.Link>
                </LinkContainer>
              </Nav.Item>
            </>
          )}

        {/* Auditor debe ver el inicio específico */}
        {role === "audit" && (
          <>
            <LinkContainer to="/inicio-auditor">
              <Nav.Link>
                <FontAwesomeIcon icon={faStore} className="fa-icon" /> Inicio
                Auditor
              </Nav.Link>
            </LinkContainer>

            <Nav.Item>
              <LinkContainer to="/lista-de-transferencias-auditor">
                <Nav.Link>
                  <FontAwesomeIcon
                    icon={faMoneyBillTransfer}
                    className="fa-icon"
                  />
                  Transferencias
                </Nav.Link>
              </LinkContainer>
            </Nav.Item>

            {/* Mostrar sucursales para auditores */}
            {loading && <div>Cargando sucursales...</div>}
            {error && <div>{error}</div>}
            {!loading && !error && branches.length > 0
              ? branches.map((branch) => (
                  <Nav.Item key={branch.id}>
                    <LinkContainer to={`/cajas-sucursales/${branch.id}`}>
                      <Nav.Link>
                        <FontAwesomeIcon
                          icon={faCashRegister}
                          className="fa-icon"
                        />
                        {branch.name}
                      </Nav.Link>
                    </LinkContainer>
                  </Nav.Item>
                ))
              : !loading && <div>No se encontraron sucursales.</div>}
          </>
        )}

        {/* Opciones para Empleado o GSuc */}
        {(role === "Emp" || role === "gsuc") && (
          <>
            <LinkContainer to="/mi-comercio">
              <Nav.Link>
                <FontAwesomeIcon icon={faStore} className="fa-icon" /> Inicio
              </Nav.Link>
            </LinkContainer>

            <Nav.Item>
              <Nav.Link onClick={handleSaldosClick}>
                <FontAwesomeIcon icon={faWallet} className="fa-icon" /> Saldos
              </Nav.Link>
            </Nav.Item>
            {isSaldosOpen && (
              <>
                <LinkContainer to="/recarga-saldo">
                  <Nav.Link className="sub-option">
                    <FontAwesomeIcon
                      icon={faMoneyBillWave}
                      className="fa-icon"
                    />
                    Nuevas recargas
                  </Nav.Link>
                </LinkContainer>
                <LinkContainer to="/cash-out">
                  <Nav.Link className="sub-option">
                    <FontAwesomeIcon
                      icon={faHandHoldingDollar}
                      className="fa-icon"
                    />
                    Nueva Extracción
                  </Nav.Link>
                </LinkContainer>
                <LinkContainer to="/lista-de-saldos">
                  <Nav.Link className="sub-option">
                    <FontAwesomeIcon icon={faList} className="fa-icon" /> Lista
                    de movimientos
                  </Nav.Link>
                </LinkContainer>
              </>
            )}

            <Nav.Item>
              <Nav.Link onClick={handleAdminClick}>
                <FontAwesomeIcon icon={faCogs} className="fa-icon" /> Funciones
                Administrativas
              </Nav.Link>
            </Nav.Item>
            {isAdminOpen && (
              <>
                <LinkContainer to="/funciones-administrativas/abrir-caja">
                  <Nav.Link className="sub-option">
                    <FontAwesomeIcon
                      icon={faCashRegister}
                      className="fa-icon"
                    />
                    Abrir Caja
                  </Nav.Link>
                </LinkContainer>
                <LinkContainer to="/funciones-administrativas/cerrar-caja">
                  <Nav.Link className="sub-option">
                    <FontAwesomeIcon
                      icon={faCashRegister}
                      className="fa-icon"
                    />
                    Cerrar Caja
                  </Nav.Link>
                </LinkContainer>
              </>
            )}

            {role === "gsuc" && (
              <Nav.Item>
                <LinkContainer to="/todas-las-cajas">
                  <Nav.Link>
                    <FontAwesomeIcon
                      icon={faCashRegister}
                      className="fa-icon"
                    />
                    Todas las Cajas
                  </Nav.Link>
                </LinkContainer>
              </Nav.Item>
            )}
          </>
        )}

        {/* Opciones para SUSA */}
        {role === "SUSA" && (
          <>
            <LinkContainer to="/inicio">
              <Nav.Link>
                <FontAwesomeIcon icon={faStore} className="fa-icon" /> Inicio
              </Nav.Link>
            </LinkContainer>

            <Nav.Item>
              <Nav.Link onClick={handleEmpleadosClick}>
                <FontAwesomeIcon icon={faUsers} className="fa-icon" /> Empleados
              </Nav.Link>
            </Nav.Item>
            {isEmpleadosOpen && (
              <>
                <LinkContainer to="/agregar-empleado">
                  <Nav.Link className="sub-option">
                    <FontAwesomeIcon icon={faUserPlus} className="fa-icon" />
                    Agregar empleados
                  </Nav.Link>
                </LinkContainer>
                <LinkContainer to="/empleados-admin">
                  <Nav.Link className="sub-option">
                    <FontAwesomeIcon icon={faList} className="fa-icon" /> Lista
                    Empleados
                  </Nav.Link>
                </LinkContainer>
              </>
            )}

            <Nav.Item>
              <Nav.Link onClick={handleSucursalesClick}>
                <FontAwesomeIcon icon={faBuilding} className="fa-icon" />
                Sucursales
              </Nav.Link>
            </Nav.Item>
            {isSucursalesOpen && (
              <>
                <LinkContainer to="/agregar-sucursal">
                  <Nav.Link className="sub-option">
                    <FontAwesomeIcon icon={faBuilding} className="fa-icon" />
                    Agregar sucursales
                  </Nav.Link>
                </LinkContainer>
                <LinkContainer to="/lista-de-sucursales">
                  <Nav.Link className="sub-option">
                    <FontAwesomeIcon icon={faList} className="fa-icon" /> Lista
                    Sucursales
                  </Nav.Link>
                </LinkContainer>
              </>
            )}

            <Nav.Item>
              <LinkContainer to="/lista-de-transferencias">
                <Nav.Link>
                  <FontAwesomeIcon
                    icon={faMoneyBillTransfer}
                    className="fa-icon"
                  />
                  Transferencias
                </Nav.Link>
              </LinkContainer>
            </Nav.Item>
          </>
        )}

        {/* Opción de cerrar sesión */}
        <LinkContainer to="/funciones-administrativas/logout">
          <Nav.Link onClick={handleLogout}>
            <FontAwesomeIcon icon={faSignOutAlt} className="fa-icon" /> Cerrar
            sesión
          </Nav.Link>
        </LinkContainer>
      </Nav>
    </div>
  );
}

export default Sidebar;
