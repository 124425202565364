import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import './ConfirmModal.css'; 

const ConfirmModal = ({ show, handleClose, handleConfirm }) => {
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton className="confirm-modal-header">
        <Modal.Title className="confirm-modal-title">Cerrar caja</Modal.Title>
      </Modal.Header>
      <Modal.Body className="confirm-modal-body">
        ¿Seguro que deseas cerrar caja?
      </Modal.Body>
      <Modal.Footer className="confirm-modal-footer">
        <Button variant="secondary" onClick={handleClose} className="confirm-modal-cancel">
          Cancelar
        </Button>
        <Button variant="primary" onClick={handleConfirm} className="confirm-modal-button">
          Confirmar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmModal;
