import { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { FaEdit } from "react-icons/fa";

import { Divider } from "./divider";
import { Tag } from "./tag";

export const ModalConfirmation = ({
  onCancel,
  onSuccess,
  userData,
  show,
  onChangeBalance,
  balance,
}) => {
  const [isBalanceDisabled, setIsBalanceDisabled] = useState(true);
  const isValidTicket = () => {
    if (userData?.dateExpiration) {
      const ticketDate = new Date(userData.dateExpiration);
      const today = new Date();
    
      return ticketDate.toISOString().split("T")[0] > today.toISOString().split("T")[0];
    }
    return false
  };

  return (
    <Modal show={show} centered>
      <Modal.Header
        style={{
          borderBottom: 0,
          display: "flex",
          justifyContent: "space-between",
          marginInline: "2rem",
        }}
      >
        <Modal.Title style={{ color: "var(--primary-color)" }}>
          Extracción
        </Modal.Title>
      </Modal.Header>

      <Modal.Body
        style={{
          marginInline: "2rem",
        }}
      >
        <div>
          <h5 style={{ color: "#a2a2a2", fontSize: "18px" }}>
            Información del Usuario
          </h5>
          <Divider />
          <Form>
            <Form.Group>
              <Form.Label style={{ marginBottom: "0.5rem" }}>Dni</Form.Label>
              <Form.Control
                id="dni"
                type="text"
                defaultValue={userData?.dni || ""}
                disabled
                required
              />
            </Form.Group>
            <Form.Group style={{ paddingBlock: "2rem" }}>
              <Form.Label>Email</Form.Label>
              <Form.Control
                id="email"
                type="text"
                defaultValue={userData?.email || ""}
                disabled
                required
              />
            </Form.Group>
          </Form>
          <Divider />
        </div>

        <Form style={{ gap: "2rem", marginTop: "1rem" }}>
          <Form.Group>
            <Form.Label>PIN</Form.Label>
            <Form.Control
              id="pin"
              defaultValue={userData?.pin || ""}
              disabled
              required
            />
          </Form.Group>
          <Form.Group style={{ paddingTop: "2rem" }}>
            <Form.Label>Balance</Form.Label>
            <div
              style={{ display: "flex", gap: "0.5rem", alignItems: "baseline" }}
            >
              <p>$</p>
              <Form.Control
                id="balance"
                type="currency"
                value={balance}
                disabled={isBalanceDisabled}
                required
                inputMode="decimal"
                onChange={onChangeBalance}
              />
              <Button
                style={{
                  backgroundColor: "var(--primary-color)",
                  border: "none",
                }}
                onClick={() => setIsBalanceDisabled(false)}
                disabled={!isBalanceDisabled}
              >
                <FaEdit size={14} />
              </Button>
            </div>
          </Form.Group>
          <div
            style={{
              display: "flex",
              gap: "1rem",
              justifyContent: "space-between",
            }}
          >
            <div className="modal-confirmation-tickets-date">
              <p>Creación del Ticket</p>
              <Tag
                value={
                  userData?.dateOperation
                    ? new Date(userData?.dateOperation).toLocaleDateString()
                    : "-"
                }
              />
            </div>
            <div className="modal-confirmation-tickets-date">
              <p>Vencimiento del Ticket</p>
              <Tag
                color={
                  userData?.dateExpiration
                    ? isValidTicket()
                      ? "success"
                      : "error"
                    : undefined
                }
                value={
                  userData?.dateExpiration
                    ? new Date(userData?.dateExpiration).toLocaleDateString()
                    : "-"
                }
              />
            </div>
          </div>
        </Form>
      </Modal.Body>

      <Modal.Footer
        style={{
          borderTop: 0,
          marginInline: "2rem",
        }}
      >
        <Button variant="secondary" onClick={onCancel}>
          Cancelar
        </Button>
        <Button
          style={{
            backgroundColor: "var(--primary-color)",
            borderColor: "var(--primary-color)",
          }}
          onClick={onSuccess}
          disabled={!isValidTicket()}
        >
          Extraer
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
