import React, { useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

function PrivateRoute({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    let role = localStorage.getItem('role');
    const nameShop = localStorage.getItem('nameShop');

    if (token) {
      if (!role) {
        // Si no hay rol explícito, lo asignamos
        if (nameShop) {
          role = 'comercio';
        } else {
          role = 'Emp';
        }
        localStorage.setItem('role', role);
      }

      // Solo marcamos como autenticado cuando tenemos un token válido
      setIsAuthenticated(true);
    } else {
      // Si no hay token, el usuario no está autenticado
      setIsAuthenticated(false);
    }

    // Una vez que la verificación está completa, marcamos como inicializado
    setIsInitialized(true);
  }, []);

  // Mostrar una pantalla de carga mientras se verifica la autenticación
  if (!isInitialized) {
    return <div>Cargando...</div>;
  }

  // Si no está autenticado, redirigir al login
  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  // Si está autenticado, renderiza los componentes hijos
  return children;
}

export default PrivateRoute;
