import React, { useEffect, useState } from "react";
import { Alert, Button, Card, Container, Form, Spinner } from "react-bootstrap";

import { ModalConfirmation } from "./components/modalConfirmation";
import { useCashOut } from "../../Hooks/Susa/useFetchCashOut";
import "./index.css";

function CashOut() {
  const { userData, loading, success, error, getInfoCashOut, postCashOut } =
    useCashOut();
  const [user, setUser] = useState({
    dni: undefined,
    pin: undefined,
    balance: undefined,
  });
  const [showConfirmation, setShowConfirmation] = useState(false);
  const isDisabled = () => !user.dni || !user.pin;

  const handleOnChange = ({ target: { value, id } }) =>
    setUser({ ...user, [`${id}`]: value });

  const handleOnSubmit = async () => await getInfoCashOut(user);

  const cleanSubmit = () => {
    setShowConfirmation(false);
    setUser({ dni: undefined, pin: undefined });
  };

  const onSubmit = async () => {
    await postCashOut({ dni: user.dni, pin: user.pin, balance: user.balance });
    cleanSubmit();
  };

  useEffect(() => {
    if (userData) {
      setUser((state) => {
        return { ...state, balance: userData.balance };
      });
      setShowConfirmation(true);
      return;
    }
  }, [userData]);

  return (
    <Container className="cash-out-container">
      <Card className="cash-out-card">
        <Card.Body>
          <h2 style={{ color: "var(--primary-color)" }}>
            Buscador de Extracciones
          </h2>
          <br />
          <Form>
            <Form.Group>
              <Form.Label style={{ marginBottom: "0.5rem" }}>
                Dni del Pasajero
              </Form.Label>
              <Form.Control
                id="dni"
                type="text"
                value={user.dni || ""}
                defaultValue={user.dni || ""}
                onChange={handleOnChange}
                required
              />
            </Form.Group>
            <Form.Group>
              <Form.Label style={{ marginTop: "2rem" }}>PIN</Form.Label>
              <Form.Control
                id="pin"
                type="text"
                value={user.pin || ""}
                defaultValue={user.pin || ""}
                onChange={handleOnChange}
                required
              />
            </Form.Group>
            <Button
              variant="primary"
              disabled={isDisabled()}
              onClick={handleOnSubmit}
              className="cash-out-button-confirm"
              style={{ backgroundColor: "#118a80" }}
            >
              Buscar extracción
            </Button>
          </Form>
        </Card.Body>
      </Card>
      <Alert
        show={!!error || success}
        variant={!!error ? "danger" : "success"}
        className="alert-message"
      >
        {success ? "Accion realizada con exito" : error}
      </Alert>
      <ModalConfirmation
        userData={userData}
        show={showConfirmation}
        onCancel={cleanSubmit}
        onSuccess={onSubmit}
        onChangeBalance={handleOnChange}
        balance={user.balance ?? ""}
      />
      {loading ? (
        <Spinner
          animation="border"
          variant="warning"
          className="spinner-center"
        />
      ) : (
        <></>
      )}
    </Container>
  );
}

export default CashOut;
