export const Divider = () => (
  <div
    style={{
      height: "0.1rem",
      backgroundColor: "#f2f2f2",
      width: "100%",
      marginBlock: "1rem",
    }}
  />
);
