import React, { useState } from 'react';
import { Card, Container, Row, Col, Button, Table, Form } from 'react-bootstrap';
import axios from 'axios';

const InicioAuditor = () => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [sucursales, setSucursales] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleFetchData = async () => {
    // Validación de fechas
    if (!startDate || !endDate) {
      setError('Por favor, ingrese ambas fechas.');
      return;
    }
    if (new Date(startDate) > new Date(endDate)) {
      setError('La fecha de inicio no puede ser posterior a la fecha de fin.');
      return;
    }

    setLoading(true);
    setError(null);

    console.log('Token:', localStorage.getItem('token'));

    try {
      const token = localStorage.getItem('token');
      const API_URL = process.env.REACT_APP_URL;

      const response = await axios.post(
        `${API_URL}/public/v2/Audit/Totales`,
        { inicio: startDate, fin: endDate },
        {
          headers: {
            'SERVICIOS-URBANOS-TOKEN': token,
          },
        }
      );
      console.log('Respuesta de la API:', response.data);

      setSucursales(response.data.sucursales || []);
    } catch (err) {
      setError('Error al obtener los totales.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container className="inicio-container">
      <Card className="inicio-card shadow-lg mb-5 rounded">
        <h2 className="inicio-header">Reporte de Totales por Sucursal</h2>
        <Row>
          <Col md={6}>
            <Form.Group controlId="startDate">
              <Form.Label>Fecha de Inicio</Form.Label>
              <Form.Control
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="endDate">
              <Form.Label>Fecha de Fin</Form.Label>
              <Form.Control
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </Form.Group>
          </Col>
        </Row>
        <Button style={{backgroundColor: '#118a80', border: 'none'}} onClick={handleFetchData} className="mt-3">
          Consultar Totales
        </Button>

        {loading ? (
          <div>Cargando...</div>
        ) : error ? (
          <div>{error}</div>
        ) : (
          <Table striped bordered hover className="mt-4">
            <thead>
              <tr>
                <th>Sucursal</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {sucursales.map((sucursal) => (
                <tr key={sucursal.name}>
                  <td>{sucursal.name}</td>
                  <td>{sucursal.total}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </Card>
    </Container>
  );
};

export default InicioAuditor;
