import React, { useState } from 'react';
import axios from 'axios';
import { Form, Button, Container, Row, Col, Alert, Card } from 'react-bootstrap';
import ConfirmationModal from './ConfirmationModal';
import './RecargaSaldo.css';

function RecargaSaldo() {
  const [cuitPasajero, setCuitPasajero] = useState('');
  const [nombre, setNombre] = useState('');
  const [apellido, setApellido] = useState('');
  const [monto, setMonto] = useState('');
  const [mensaje, setMensaje] = useState('');
  const [error, setError] = useState('');
  const [montoError, setMontoError] = useState(''); 
  const [recargaConfirmada, setRecargaConfirmada] = useState(false);
  const [pasajeroValido, setPasajeroValido] = useState(false);
  const [mostrarResultado, setMostrarResultado] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [pasajeroId, setPasajeroId] = useState(''); 
  const cuitComercio = localStorage.getItem('cuit');
  const [isLoading, setIsLoading] = useState(false); // Estado para manejar si está recargando
  const [isSearching, setIsSearching] = useState(false); // Estado para manejar si está buscando pasajero
  const [isConfirming, setIsConfirming] = useState(false); 



  const handleBuscarPasajero = async () => {
    setIsSearching(true); // Activamos el estado de búsqueda
    try {
      const response = await axios.post(`${process.env.REACT_APP_URL}/public/v1/Shops/InfoPassenger`, 
        { cuitPassenger: cuitPasajero }, 
        {
          headers: {
            'SERVICIOS-URBANOS-TOKEN': localStorage.getItem('token'),
            'page': 1
          }
        }
      );
  
      if (response.data.ok) {
        const userData = response.data.msg;
        setNombre(userData.name);
        setApellido(userData.lastName);
        setPasajeroValido(true);
        setPasajeroId(userData._id);
        setError('');
      } else {
        setError(response.data.msg);
        setPasajeroValido(false);
        setNombre('');  // Limpiar campos
        setApellido(''); // Limpiar campos
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.msg) {
        setError(error.response.data.msg);
      } else {
        setError('Error al obtener la información del pasajero. Inténtalo de nuevo.');
      }
      setPasajeroValido(false);
      setNombre('');  // Limpiar campos
      setApellido(''); // Limpiar campos
    } finally {
      setIsSearching(false); // Restauramos el estado de búsqueda
    }
  };
  

  const handleRecarga = async () => {
    const data = { 
      _id: pasajeroId, 
      credito: parseFloat(monto) 
    };
    try {
      const response = await axios.post(`${process.env.REACT_APP_URL}/public/v2/Venta/Saldo`, 
        data, 
        {
          headers: {
            'Content-Type': 'application/json',
            'SERVICIOS-URBANOS-TOKEN': localStorage.getItem('token'),
            'page': 1
          }
        }
      );
      setMensaje(response.data.msg);
      setError('');
      setRecargaConfirmada(true);
      setMostrarResultado(true);
    } catch (error) {
      if (error.response && error.response.data && error.response.data.msg) {
        setError(error.response.data.msg);
      } else {
        setError('Error al realizar la recarga. Inténtalo de nuevo.');
      }
      setMensaje('');
      setMostrarResultado(true);
    } finally {
      setIsLoading(false); // Restauramos el estado para que el botón vuelva a estar activo
    }
  };
  

  const handleOtraRecarga = () => {
    setMostrarResultado(false);
    setCuitPasajero('');
    setNombre('');
    setApellido('');
    setMonto('');
    setMensaje('');
    setError('');
    setMontoError(''); 
    setRecargaConfirmada(false);
    setPasajeroValido(false);
  };

  const handleConfirm = () => {
    if (parseFloat(monto) <= 0) {
      setMontoError('El monto debe ser mayor a 0');
    } else {
      setMontoError('');
      setShowModal(true);
    }
  };



  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleConfirmModal = async () => {
    setIsConfirming(true); // Cambiar el estado a confirmando
    setIsLoading(true); 
    await handleRecarga(); 
    setIsConfirming(false); // Cambiar el estado de confirmación después de la solicitud
    setShowModal(false); 
  };
  

  const handleMontoChange = (e) => {
    const value = e.target.value;
    const regex = /^\d*\.?\d{0,2}$/;
    if (regex.test(value) || value === '') {
      setMonto(value);
      setMontoError(''); 
    }
  };

  return (
    <Container className="recarga-container">
      {mostrarResultado ? (
        <Card className="recarga-card">
          <Row className="justify-content-md-center">
            <Col md="12">
              <h2 className="recarga-header">Resultado de la Recarga</h2>
              <p>CUIT del Pasajero: {cuitPasajero}</p>
              <p>Monto Acreditado: ${monto}</p>
              {mensaje && <Alert variant="success">{mensaje}</Alert>}
              {error && <Alert variant="danger">{error}</Alert>}
              <Button 
                variant="primary" 
                onClick={handleOtraRecarga} 
                className="recarga-button"
              >
                Realizar otra recarga
              </Button>
            </Col>
          </Row>
        </Card>
      ) : (
        <Card className="recarga-card">
          <Row className="justify-content-md-center">
            <Col md="12">
              <h2 className="recarga-header">Carga de saldo pasajeros</h2>
              {mensaje && <Alert variant="success">{mensaje}</Alert>}
              {error && <Alert variant="danger">{error}</Alert>}
              {montoError && <Alert variant="danger">{montoError}</Alert>} 
              <Form>
                <Form.Group controlId="formCuitPasajero">
                  <Form.Label style={{marginTop: '10px'}}>DNI o CUIT del Pasajero</Form.Label>
                  <Form.Control
                    type="text"
                    value={cuitPasajero}
                    onChange={(e) => {
                      setCuitPasajero(e.target.value);
                      setPasajeroValido(false);
                    }}
                  />
                </Form.Group>
                {!recargaConfirmada && (
                  <Button 
                  variant="primary" 
                  onClick={handleBuscarPasajero} 
                  className="recarga-button-confirm" 
                  disabled={isSearching || !cuitPasajero} // Deshabilitar si está buscando o si el CUIT no está ingresado
                >
                  {isSearching ? 'Buscando...' : 'Buscar Pasajero'}
                </Button>
                
                )}
              </Form>
              {!recargaConfirmada ? (
                <Form style={{marginTop:'30px'}}>
                  <Form.Group controlId="formNombre">
                    <Form.Label>Nombre completo</Form.Label>
                    <Form.Control
                      type="text"
                      value={`${nombre} ${apellido}`}
                      readOnly
                    />
                  </Form.Group>
                  <Form.Group controlId="formMonto">
                    <Form.Label style={{marginTop: '10px'}}>Monto por acreditar</Form.Label>
                    <Form.Control
                      type="text"
                      value={monto}
                      onChange={handleMontoChange}
                      required
                    />
                  </Form.Group>
                  <Button 
                   variant="primary" 
                   onClick={handleConfirm} 
                   className="recarga-button-confirm"
                   disabled={!pasajeroValido || !cuitPasajero || !monto || isLoading} // Desactivar si estamos recargando
                    >
                      {isLoading ? 'Recargando...' : 'Confirmar'}
                 </Button>

                </Form>
              ) : (
                <div>
                  <h3 className="recarga-exito">Saldo cargado con éxito</h3>
                </div>
              )}
            </Col>
          </Row>
        </Card>
      )}
      <ConfirmationModal 
        show={showModal} 
        handleClose={handleCloseModal} 
        handleConfirm={handleConfirmModal}
        cuitPasajero={cuitPasajero}
        nombrePasajero={`${nombre} ${apellido}`}
        monto={monto}
        isConfirming={isConfirming}
      />
    </Container>
  );
}

export default RecargaSaldo;
