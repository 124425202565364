import React from 'react';
import { Modal, Table, Button } from 'react-bootstrap';

const DetalleSaldoModal = ({ show, handleClose, detalleSaldo }) => {
  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); 
    const year = String(date.getUTCFullYear()).slice(-2); 
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    const seconds = String(date.getUTCSeconds()).padStart(2, '0');

    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
  };

  return (
    <Modal show={show} onHide={handleClose} centered dialogClassName="modal-custom">
      <Modal.Header closeButton>
        <Modal.Title>Detalles</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {detalleSaldo ? (
          <Table bordered className="detalle-saldo-table">
            <tbody>
              {/*<tr>
                <td><strong>Fecha:</strong></td>
                <td>{formatDate(detalleSaldo.timestamp)}</td>
        </tr>*/}
              {detalleSaldo.movimiento.types === 'Venta de Saldo' && (
                <>
                  <tr>
                    <td><strong>Nombre:</strong></td>
                    <td>{detalleSaldo.movimiento.nameOrig || '-'}</td>
                  </tr>
                  <tr>
                    <td><strong>Apellido:</strong></td>
                    <td>{detalleSaldo.movimiento.lastNameOrig || '-'}</td>
                  </tr>
                  <tr>
                  <td><strong>ID de Caja:</strong></td>
                  <td>{detalleSaldo.metadata.idBox || '-'}</td>
                  </tr>
                  <tr>
                    <td><strong>CUIT:</strong></td>
                    <td>{detalleSaldo.movimiento.cuit || '-'}</td>
                  </tr>
                  <tr>
                    <td><strong>CVU:</strong></td>
                    <td>{detalleSaldo.movimiento.cvu || '-'}</td>
                  </tr>
                  <tr>
                    <td><strong>Balance:</strong></td>
                    <td>${detalleSaldo.movimiento.balance?.toFixed(2) || '0.00'}</td>
                  </tr>
                </>
              )}
              {detalleSaldo.movimiento.types === 'Cierre de Caja' && (
                <>
                  <tr>
                    <td><strong>Balance:</strong></td>
                    <td>${detalleSaldo.movimiento.balance?.toFixed(2) || '0.00'}</td>
                  </tr>
                  <tr>
                    <td><strong>Observación:</strong></td>
                    <td>{detalleSaldo.movimiento.observation || '-'}</td>
                  </tr>
                  <tr>
                    <td><strong>Inicio de Caja:</strong></td>
                    <td>${detalleSaldo.movimiento.boxStart?.toFixed(2) || '0.00'}</td>
                  </tr>
                  <tr>
                    <td><strong>Saldo en Caja:</strong></td>
                    <td>${detalleSaldo.movimiento.boxCash ? detalleSaldo.movimiento.boxCash.toFixed(2) : '0.00'}</td>
                  </tr>
                  {/*<tr>
                    <td><strong>Estado:</strong></td>
                    <td>{detalleSaldo.movimiento.state ? 'Activo' : 'Inactivo'}</td>
                  </tr>*/}
                  <tr>
                    <td><strong>Tipo:</strong></td>
                    <td>{detalleSaldo.movimiento.types}</td>
                  </tr>
                </>
              )}
            </tbody>
          </Table>
        ) : (
          <p>Cargando detalles...</p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DetalleSaldoModal;
