import { useState, useEffect } from 'react';
import axios from 'axios';

const useFetchCajas = (page, startDate, endDate, name, lastName) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem('token');

      if (startDate && endDate) {
        setLoading(true);
        setError(null);

        // Construir el payload dinámicamente
        const payload = {
          page,
          inicio: startDate,
          fin: endDate,
          ...(name && { name }),
          ...(lastName && { lastName }),
        };

        try {
          const response = await axios.post(
            `${process.env.REACT_APP_URL}/public/v2/GSuc/ListBoxBranch`,
            payload,
            {
              headers: {
                'SERVICIOS-URBANOS-TOKEN': token,
              },
            }
          );

          // Normalizar la respuesta para tener siempre un array de objetos
          const normalizedData = Array.isArray(response.data.msg[0])
            ? response.data.msg[0]
            : response.data.msg;

          setData(normalizedData);
        } catch (err) {
          setError(err.message);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchData();
  }, [page, startDate, endDate, name, lastName]);

  return { data, loading, error };
};

export default useFetchCajas;
