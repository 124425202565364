import React from 'react';
import { Navbar, Container, Row, Col } from 'react-bootstrap';
import logo from '../../assets/logo-susa-corto.png';
import './Header.css';

function Header() {
  const nombre = localStorage.getItem('nombre'); 
  const correo = localStorage.getItem('correo'); 
  const username = localStorage.getItem('username'); 
  const nameShop = localStorage.getItem('nameShop'); 
  const rol = localStorage.getItem('role'); 

  console.log('nombre:', nombre);
  console.log('username:', username);
  console.log('comercio:', nameShop);
  console.log('correo:', correo);
  console.log('rol:', rol);

  // Determina qué nombre mostrar basado en el rol
  let displayName = rol === 'Emp' ? username : nombre || rol === 'SUSA' ? username : nameShop || rol === 'gsuc' ? username : nombre || rol === 'audit' ? username : nombre;

  return (
    <Navbar className="header-navbar">
      <Container fluid className="d-flex justify-content-between align-items-center">
        <Row className="align-items-center">
          <Col className="logo-col">
            <img
              src={logo}
              height="40"
              className="d-inline-block align-top"
              alt="Logo" 
            />
          </Col>
          <Col className="username-col">
            {displayName && displayName.trim() && (  
              <Navbar.Text className="username-text">
                Bienvenido, {displayName}
              </Navbar.Text>
            )}
          </Col>
        </Row>
      </Container>
    </Navbar>
  );
}

export default Header;
