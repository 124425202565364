export const Tag = ({ value, color }) => {
  const backgroundColor = color
    ? color === "error"
      ? "#ff6e6e"
      : "#b0f4be"
    : "#e9e9e9";
  return (
    <div>
      <div
        style={{
          backgroundColor,
          minWidth: "1rem",
          borderRadius: "7px",
        }}
      >
        <p
          style={{
            textAlign: "center",
            padding: "0.4rem",
          }}
        >
          {value}
        </p>
      </div>
    </div>
  );
};
