import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Button, Card, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';  // Importar useNavigate
import './ListaDeCajas.css';

const ListaDeCajas = () => {
  const [cajasCerradas, setCajasCerradas] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [error, setError] = useState('');
  const comercioId = localStorage.getItem('comercioId');
  
  const navigate = useNavigate();  // Hook para navegar entre rutas

  useEffect(() => {
    fetchCajasCerradas(1);
  }, []);

  const fetchCajasCerradas = async (page) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_URL}/public/v2/List/Boxes`,
        {
          headers: {
            'Content-Type': 'application/json',
            'SERVICIOS-URBANOS-TOKEN': localStorage.getItem('token'),
          },
          params: {
            _id: comercioId,
            page: page,
          },
        }
      );

      if (response.status === 200 && response.data.ok) {
        setCajasCerradas(response.data.msg);
        setCurrentPage(page);
        setTotalPages(Math.ceil(response.data.msg.length / 5));
      } else {
        setCajasCerradas([]);
        setTotalPages(1);
        setError('No se encontraron cajas cerradas.');
      }
    } catch (error) {
      console.error('Error fetching cajas cerradas:', error);
      setError('Error al obtener las cajas cerradas. Inténtelo de nuevo.');
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      fetchCajasCerradas(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      fetchCajasCerradas(currentPage - 1);
    }
  };

  const formatTimestamp = (timestamp) => {
    if (!timestamp) return '-'; 
    const date = new Date(timestamp);
    const day = String(date.getUTCDate()).padStart(2, '0');
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const year = date.getUTCFullYear();
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    const seconds = String(date.getUTCSeconds()).padStart(2, '0');
    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
  };

  const handleViewHistory = (idBox) => {
    // Navegar al nuevo componente con el idBox como parámetro
    navigate(`/historial/${idBox}`);
  };

  return (
    <Card className="lista-de-cajas-card">
      <h3 className="lista-de-cajas-header">Cajas Cerradas</h3>
      {error && <Alert variant="danger">{error}</Alert>}
      <Table striped bordered hover className="lista-de-cajas-table">
        <thead>
          <tr>
            <th>ID de Caja</th>
            <th>Fecha Apertura</th>
            <th>Fecha Cierre</th>
            <th>Balance</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {cajasCerradas.slice((currentPage - 1) * 5, currentPage * 5).map((caja) => (
            <tr key={caja._id}>
              <td>{caja.idBox}</td>
              <td>{formatTimestamp(caja.dateOpen)}</td>
              <td>{formatTimestamp(caja.dateClose)}</td>
              <td>${caja.balance ? caja.balance.toFixed(2) : '0.00'}</td>
              <td>
                <Button variant='link' onClick={() => handleViewHistory(caja.idBox)}>Ver historial</Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <div className="lista-de-cajas-buttons">
        <Button
          variant="secondary"
          onClick={handlePreviousPage}
          disabled={currentPage === 1}
        >
          Anterior
        </Button>
        <Button
          variant="secondary"
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
        >
          Siguiente
        </Button>
      </div>
    </Card>
  );
};

export default ListaDeCajas;
