import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import axios from 'axios';

const CajaModal = ({ show, onHide, idBox }) => {
  const [cajaDetails, setCajaDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const formatFechaExacta = (fecha) => {
    const [date, time] = fecha.split('T'); 
    const [year, month, day] = date.split('-');
    return `${day}-${month}-${year.slice(2)} ${time.split('.')[0]}`; 
  };

  useEffect(() => {
    const fetchCajaDetails = async () => {
      setLoading(true);
      setError(null);
      try {
        const token = localStorage.getItem('token');
        const API_URL = process.env.REACT_APP_URL;

        const response = await axios.post(
          `${API_URL}/public/v2/Audit/ListBox`,
          { idBox },  // Enviamos el idBox en el body
          {
            headers: {
              'SERVICIOS-URBANOS-TOKEN': token,
            },
          }
        );

        const details = response.data.msg[0]; 
        setCajaDetails(details);
      } catch (err) {
        setError('Error al obtener los detalles de la caja.');
      } finally {
        setLoading(false);
      }
    };

    if (idBox) {
      fetchCajaDetails();
    }
  }, [idBox]);

  if (loading) return <div>Cargando detalles...</div>;
  if (error) return <div>{error}</div>;

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title style={{ color: '#fd6b0c' }}>Detalles de la Caja</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {cajaDetails ? (
          <div>
            <p><strong>ID Caja:</strong> {cajaDetails.idBox}</p>
            <p><strong>ID Sucursal:</strong> {cajaDetails.idBranches}</p>
            <p><strong>ID Empleado:</strong> {cajaDetails.idEmployees}</p>
            <p><strong>Nombre Empleado:</strong> {cajaDetails.empleado.name} {cajaDetails.empleado.lastName}</p>
            <p><strong>Email del Empleado:</strong> {cajaDetails.empleado.email}</p>
            <hr />
            
            <p><strong>Fecha de Apertura:</strong> {formatFechaExacta(cajaDetails.dateOpen)}</p>
            <p><strong>Fecha de Cierre:</strong> {cajaDetails.dateClose ? formatFechaExacta(cajaDetails.dateClose) : 'Sin cerrar'}</p>
            <p><strong>Balance:</strong> ${cajaDetails.balance}</p>
            <hr />
            <h5 style={{color: '#118a80'}}> Detalles del comercio</h5>
            <p><strong>Nombre del Comercio:</strong> {cajaDetails.comercio.nameShop}</p>
            <p><strong>CUIT del Comercio:</strong> {cajaDetails.comercio.cuit}</p>
            <p><strong>Email del Comercio:</strong> {cajaDetails.comercio.email}</p>
            <p><strong>Dirección:</strong> {cajaDetails.comercio.address}, {cajaDetails.comercio.city}, {cajaDetails.comercio.province}</p>
          </div>
        ) : (
          <div>No se encontraron detalles para esta caja.</div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cerrar
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CajaModal;
