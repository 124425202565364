import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Table, Alert, Spinner, Card } from 'react-bootstrap';

const HistorialCaja = () => {
  const { idBox } = useParams();  // Obtén el idBox de los parámetros de la URL
  const [movimientos, setMovimientos] = useState([]);
  const [error, setError] = useState('');
  const [noHistorial, setNoHistorial] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // Estado para mostrar el cargando

  useEffect(() => {
    fetchHistorialCaja();
  }, [idBox]);

  const fetchHistorialCaja = async () => {
    setIsLoading(true);  // Empezar la carga
    try {
      const response = await axios.post(`${process.env.REACT_APP_URL}/public/v2/List/History`, {
        idBox: idBox
      }, {
        headers: {
          'Content-Type': 'application/json',
          'SERVICIOS-URBANOS-TOKEN': localStorage.getItem('token')
        }
      });

      if (response.status === 200 && response.data.ok) {
        const movimientosData = response.data.msg;
        if (movimientosData.length === 0) {
          setNoHistorial(true); // Indicar que no hay historial
        } else {
          setMovimientos(movimientosData);
          setNoHistorial(false); // Si hay movimientos, ocultar el mensaje
        }
      } else {
        setNoHistorial(true); // Si no está bien la respuesta, mostrar que no hay historial
      }
    } catch (error) {
      console.error('Error fetching historial de caja:', error);
      setError('Error al obtener el historial de la caja. Inténtelo de nuevo.');
    } finally {
      setIsLoading(false);  // Finalizar la carga
    }
  };

  const formatCash = (cash) => {
    if (typeof cash === 'number') {
      return cash.toFixed(2);
    }
    return '0.00'; // Valor por defecto si no es un número
  };

  return (
    <Card className='historial-de-cajas-card'>
        <Card.Body>
      <h3>Historial de Caja {idBox}</h3>
      {error && <Alert variant="danger">{error}</Alert>}
      {isLoading ? (
        <div className="text-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Cargando historial...</span>
          </Spinner>
          <p>Cargando historial...</p>
        </div>
      ) : (
        noHistorial ? (
          <Alert variant="warning">Esta caja no posee historial.</Alert>
        ) : (
          movimientos.length > 0 && (
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Nombre</th>
                  <th>Apellido</th>
                  <th>Balance</th>
                  <th>CVU</th>
                  <th>CUIT</th>
                  <th>Tipo de Movimiento</th>
                </tr>
              </thead>
              <tbody>
                {movimientos.map((mov, index) => (
                  <tr key={index}>
                    <td>{mov.movimiento.nameOrig}</td>
                    <td>{mov.movimiento.lastNameOrig}</td>
                    <td>${formatCash(mov.movimiento.boxCash)}</td>
                    <td>{mov.movimiento.cvu}</td>
                    <td>{mov.movimiento.cuit}</td>
                    <td>{mov.movimiento.types}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )
        )
      )}
      </Card.Body>
    </Card>
  );
};

export default HistorialCaja;
